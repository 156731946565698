import React from 'react'

export default function EqualityContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Equality, Diversity and Tolerance",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"All people are of equal human worth. Our company values diversity – we embrace the uniqueness of each person. We understand that it is through our differences – in race, gender identity or expression, age, religion, sexual orientation, family status, ability, and other qualities – that we are collectively stronger and more resilient. We are committed to creating a safe and inclusive workplace community absent of judgment and imbued with kindness. Individuals vary in many ways – their values, tastes, moods and methods to achieve goals. In our organization, these types of differences between individual colleagues will be respected and tolerated, always.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Égalité, diversité et tolérance",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Toutes les personnes ont la même valeur humaine. Notre entreprise valorise la diversité - nous célébrons le caractère unique de chaque personne. Nous comprenons que c'est grâce à nos différences - de race, d'identité ou d'expression de genre, d'âge, de religion, d'orientation sexuelle, de situation familiale, de capacité et d'autres qualités - que nous sommes collectivement plus forts et plus résilients. Nous nous engageons à créer une communauté de travail sûre et inclusive, sans jugement et empreinte de bienveillance. Les individus varient à bien des égards - leurs valeurs, leurs goûts, leurs humeurs et leurs méthodes pour atteindre leurs objectifs. Dans notre organisation, ces types de différences entre collègues seront toujours respectés et tolérés.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
