import React from 'react'

export default function MeetOurTeamContent() {
    return (
        {
            'en': {
                "title": "MEET OUR TEAM",
                "ThomasKrayer": {
                    "title": "Senior Colleague, Founder",
                    "ThomasKrayerText": "Over 25 years, Thomas has managed over 100 telemarketing campaigns in both official languages, generating over $43 million in revenues, almost exclusively for non-profit organizations. As President and CEO of Generosité Fundraising & Communications, his goal is to continue to build on the firm’s already impressive roster of clients by surpassing expectations with his teams’ results while maintaining an extremely high level of customer service in both of Canada’s official languages. Thomas takes great pride in having founded one of Canada’s top firms with the expertise, experience, knowledge, and know-how to consistently achieve telephone membership acquisition and fundraising revenue objectives in both French and English language campaigns. He is an expert communicator and has a hands-on management style that ensures that the job gets done well and on time!"
                },
                "SethWilcox": {
                    "title": "Senior Colleague, Strategy & Development",
                    "SethWilcoxText": "Seth is a results-driven fundraising and management professional with over ten years of experience. Originally introduced to the industry through an American telecommunications giant with over thirty million subscribers, Seth now passionately serves clients across Canada in the non-profit sector, achieving hundreds of thousands of dollars a year in revenue. Seth has a proven track record of management success and is passionate about philanthropy. Seth is an excellent problem-solver with a high degree of technical aptitude with knowledge in SQL based databases and CRM software for fundraising. A proficient technical writer and graphic designer, Seth has created marketing materials used in email, phone, and written communications for various clients. Seth utilizes a diverse multitude of skills to develop outside-the-box strategies for all our clients. He provides ongoing analysis and strategic support for almost every campaign that Generosité engages with."
                },
                "LucPerron": {
                    "title": "Senior Colleague, Operations",
                    "LucPerronText": "Luc is a dynamic team member with previous experience in life insurance, luxury hotel management, and pastoral care. With ten years of experience working on fundraising and sales campaigns, Luc is an important member of Generosité Fundraising & Communications’ team. He is involved in nearly every campaign that Generosité is engaged with. Luc has unsurpassed knowledge in the administration of Generosité’s web-based CRM solution, and is typically involved in the retrieval, formatting, and uploading of data. Luc is fully bilingual, and an expert communicator in both English and French. He provides translation services for a wide variety of purposes to all our clients. Dedicated, hardworking, extremely reliable, Luc is a real team player."
                },
                "JeanFrançois": {
                    "title": "Senior Colleague, Campaign Coordinator",
                    "JeanFrancoisText": "With 15 years of experience in sales and 10 years of experience in fundraising, Jean-François is a goal-oriented person. Generating hundreds of thousands of dollars in revenue annually, Jean-François expertly motivates team members to make sure that they are the best they can be. He has vast knowledge with customer service, sales, and fundraising. Fully Bilingual, Jean-François applies his knowledge to great effect for non-profit organizations across Canada."
                },
                "RosannaMcLaughlin": {
                    "title": "Senior Colleague, Financial Administration",
                    "RosannaMcLaughlinText": "Rosanna has been in finance for over 21 years, working with various companies and non-profit organizations of all sizes. She has experience working with several accounting software programs that include Sage AccPac, Simply Accounting, and Quickbooks. She is very efficient and effective with her bookkeeping duties and is an expert in maintaining accurate record-keeping. Rosanna helps guide Generosité’s decision-making process providing insights on the firm’s finances. Her strong work ethic, combined with organizational and financial skills, have led to her key involvement with the company’s operations. Having worked for a wide variety of different organizations over the course of 21 years, Rosanna has received exceptional feedback from all her clients for her excellent work in payroll, office administration, and accounting."
                },
                "GagnéHenley": {
                    "title": "Senior Colleague, Reporting, Payroll, & Finance",
                    "GagneHenleyText": "Colombe has a strong background in accounting with over ten years of experience for Canada’s largest movie distributor, eOne Entertainment/Les Films Séville (previously known as Alliance Films) as the Television Accountant. She was involved in the migration of the finance department from Toronto to Montreal and her experience with the yearend auditing (done by accounting firms such as PwC and Deloitte) has given her a strong skillset when looking for potential issues, as well as being well versed in the latest accounting standards. Colombe is responsible for many of the reports we produce and provide to our clients on each campaign. She is also responsible for the payroll calculations for our employees on all campaigns. Colombe produces the Key Performance Indicator and Call Audit reports used to evaluate the employees on an ongoing basis."
                },
            },
            'fr': {
                "title": "Rencontrez notre équipe",
                "ThomasKrayer": {
                    "title": "Collègue Senior, Fondateur",
                    "ThomasKrayerText": "En 25 ans, Thomas a géré plus de 100 campagnes de télémarketing dans les deux langues officielles, générant plus de 43 millions de dollars de revenus, presque exclusivement pour des organismes à but non lucratif. En tant que président et chef de la direction de Generosité, son objectif est de continuer à bâtir sur la liste déjà impressionnante de clients de la firme en dépassant les attentes avec les résultats de ses équipes tout en maintenant un niveau de service à la clientèle extrêmement élevé dans les deux langues officielles du Canada. Thomas est très fier d'avoir fondé l'une des meilleures entreprises au Canada possédant l'expertise, l'expérience, les connaissances et le savoir-faire nécessaires pour atteindre constamment les objectifs d'acquisition d'adhésions téléphoniques et de revenus de collecte de fonds dans les campagnes en français et en anglais. Il est un communicateur expert et possède un style de gestion pratique qui garantit que le travail est bien fait et à temps!"
                },
                "SethWilcox": {
                    "title": "Collègue Senior, Stratégie et développement",
                    "SethWilcoxText": "Seth est un professionnel de la collecte de fonds et de la gestion axé sur les résultats avec plus de dix ans d'expérience. Initialement introduit dans l'industrie par l'intermédiaire d'un géant américain des télécommunications comptant plus de trente millions d'abonnés, Seth sert maintenant avec passion des clients partout au Canada dans le secteur sans but lucratif, réalisant des centaines de milliers de dollars de revenus par année. Seth a fait ses preuves en matière de succès en gestion et est passionné par la philanthropie. Seth est un excellent résolveur de problèmes avec un haut degré d'aptitude technique et des connaissances dans les bases de données SQL et les logiciels CRM pour la collecte de fonds. Rédacteur technique et graphiste compétent, Seth a créé du matériel de marketing utilisé dans les communications par courriel, par téléphone et par écrit pour divers clients. Seth utilise une multitude de compétences diverses pour développer des stratégies originales pour tous nos clients. Il fournit une analyse continue et un soutien stratégique pour presque toutes les campagnes avec lesquelles Generosité s'engage."
                },
               
                "LucPerron": {
                    "title": "Collègue Senior, Opérations",
                    "LucPerronText": "Luc est un dynamique membre de l'équipe avec une expérience antérieure en assurance-vie, en gestion d'hôtels de luxe et en pastorale. Avec dix ans d'expérience dans les campagnes de financement et de vente, Luc est un membre important de l'équipe de Generosité collecte de fonds et communications. Il est impliqué dans presque toutes les campagnes avec lesquelles Generosité est engagée. Luc possède des connaissances inégalées dans l'administration de la base de données CRM de Generosité et est généralement impliqué dans la récupération, le formatage et le téléchargement des données. Luc est parfaitement bilingue et un excellent communicateur en anglais et en français. Il fournit des services de traduction pour une grande variété de fins à tous nos clients. Dévoué, travailleur, extrêmement fiable, Luc est un vrai joueur d'équipe."
                },
                "JeanFrançois": {
                    "title": "Collègue Senior, Coordonnateur de Campagne",
                    "JeanFrancoisText": "Avec 15 ans d'expérience en vente et 10 ans d'expérience en collecte de fonds, Jean-François est une personne orientée vers les objectifs. Générant des centaines de milliers de dollars de revenus annuellement, Jean-François motive habilement les membres de l'équipe pour s'assurer qu'ils sont les meilleurs possible. Il possède une vaste connaissance du service à la clientèle, des ventes et de la collecte de fonds. Entièrement bilingue, Jean-François applique ses connaissances à bon escient pour des organismes à but non lucratif à travers le Canada."
                },
                "RosannaMcLaughlin": {
                    "title": "Collègue Senior, Administration financière",
                    "RosannaMcLaughlinText": "Rosanna travaille dans la finance depuis plus de 21 ans, travaillant avec diverses entreprises et organisations à but non lucratif de toutes tailles. Elle a de l'expérience avec plusieurs logiciels de comptabilité, notamment Sage AccPac, Simple Comptable et Quickbooks. Elle est très efficace dans ses tâches de tenue de livres et est experte dans la précision de la tenue de registres. Rosanna aide à guider le processus décisionnel de Generosité en fournissant un aperçu des finances de l'entreprise. Sa solide éthique de travail, combinée à des compétences organisationnelles et financières, ont mené à son implication clé dans les opérations de l'entreprise. Ayant travaillé pour une grande variété d'organisations différentes au cours des 21 dernières années, Rosanna a reçu des commentaires exceptionnels de tous ses clients pour son excellent travail, en matière de paie, d'administration de bureau et de comptabilité."
                },
                "GagnéHenley": {
                    "title": "Collègue Senior, Rapports, paie et finance",
                    "GagneHenleyText": "Colombe possède une solide expérience en comptabilité avec plus de dix ans d'expérience pour le plus grand distributeur de films au Canada, eOne Entertainment/Les Films Séville (anciennement Alliance Films) en tant que comptable de la télévision. Elle a été impliquée dans la migration du département des finances de Toronto à Montréal et son expérience avec l'audit de fin d'année (effectué par des cabinets comptables tels que PwC et Deloitte) lui a donné une solide compétence lors de la recherche de problèmes potentiels, ainsi qu'une bonne connaissance dans les dernières normes comptables. Colombe est responsable de plusieurs des rapports que nous produisons et fournissons à nos clients sur chaque campagne. Elle est également responsable des calculs de paie pour nos employés sur toutes les campagnes. Colombe produit les rapports Key Performance Indicator et Call Audit utilisés pour évaluer les employés sur une base continue."
                },
            },
        }
    )
}
