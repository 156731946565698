import {Navbar,Nav,NavDropdown,Container, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//import logo from './images/logo.png';
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import CaringContent from './lang/CaringContent';
import CoreValuesContent from './lang/CoreValuesContent';
import DedicationContent from './lang/DedicationContent';
import AuthenticityContent from './lang/AuthenticityContent';
import GoalOrientedContent from './lang/GoalOrientedContent';
import HonestyContent from './lang/HonestyContent';
import TrustContent from './lang/TrustContent';
import AutonomyContent from './lang/AutonomyContent';
import ServiceContent from './lang/ServiceContent';
import TeamworkContent from './lang/TeamworkContent';
import EnvironmentalContent from './lang/EnvironmentalContent';
import EqualityContent from './lang/EqualityContent';

function Purpose()
{
  const { lang, setLang } = useContext(LangContext);

  const Caring = CaringContent()[lang];
  const CoreValues = CoreValuesContent()[lang];
  const Dedication = DedicationContent()[lang];
  const Authenticity = AuthenticityContent()[lang];
  const GoalOriented = GoalOrientedContent()[lang];
  const Honesty = HonestyContent()[lang];
  const Trust = TrustContent()[lang];
  const Autonomy = AutonomyContent()[lang];
  const Service = ServiceContent()[lang];
  const Teamwork = TeamworkContent()[lang];
  const Environmental = EnvironmentalContent()[lang];
  const Equality = EqualityContent()[lang];
    return (
        <div className="Purpose" id="Purpose">
         <section className="purpose-wrap pad">
          <Container>
          <Row>
          <Col md={{ span: 12}}>
            <div className="heading-section">
               <h2>{CoreValues.about}</h2>
                  <p>{CoreValues.aboutUsText }</p>
            </div>
            </Col>
            </Row>
            <Row className="purposeOuter justify-content-center">
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Caring.about}</h3>
                  <p>{Caring.aboutUsText }</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Dedication.about }</h3>
                  <p>{Dedication.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                    <h3>{Authenticity.about}</h3>
                  <p>{ Authenticity.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{GoalOriented.about }</h3>
                  <p>{GoalOriented.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Honesty.about }</h3>
                  <p>{Honesty.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Trust.about}</h3>
                  <p>{Trust.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Autonomy.about}</h3>
                    <p>{Autonomy.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Service.about}</h3>
                  <p>{ Service.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Teamwork.about}</h3>
                  <p>{Teamwork.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Environmental.about}</h3>
                  <p>{Environmental.aboutUsText}</p>
                </div>
              </Col>
              <Col md={{ span: 3 }}>
                <div className="purpose-box">
                  <h3>{Equality.about}</h3>
                    <p>{Equality.aboutUsText}</p>
                </div>
              </Col>


            </Row>
          </Container>
         </section>
        </div>
    )
}

export default Purpose