

export default function CaringContent() {
  return (
      {
        'en': {
          "home": "Home",
          "about": "Caring",
          "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
          "aboutUsText":" Caring is a word seldom used in the world of business – for us it is the bedrock upon which our organization is built. We care for each other, for our clients and for the cause they serve. We understand that we are ambassadors for the organizations who hire us – we take this to heart. We always try to meet people where they are at and understand why the cause we are promoting matters to them, while at the same time being very mindful not to impose ourselves on them. We call it 360°caring because it extends to ALL stakeholders: our colleagues, our clients, customers we interact with as well as the company that we work for.",
          "pageDoesNotExist": "Page does not exist",
          "returnToHome": "Return to home"
        },
        'fr': {
          "home": "Accueil",
          "about": "Bienveillance",
          "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
          "aboutUsText":" La bienveillance est un mot rarement utilisé dans le monde des affaires - pour nous, c'est le fondement sur lequel notre organisation est bâtie. Nous prenons soin les uns des autres, de nos clients et de la cause qu'ils servent. Nous comprenons que nous sommes des ambassadeurs pour les organisations qui nous embauchent - nous prenons cela à cœur. Nous essayons toujours de rencontrer les gens là où ils en sont et de comprendre pourquoi la cause que nous défendons leur tient à cœur, tout en étant très soucieux de ne pas nous imposer. Nous appelons cette philosophie d’affaire la bienveillance à 360° car elle s'étend à toutes les parties prenantes : nos collègues, les organisations qui nous embauchent, les clients avec lesquels nous interagissons ainsi que l'entreprise pour laquelle nous travaillons.",
          "pageDoesNotExist": "Page does not exist",
          "returnToHome": "Return to home"
        },
      }
  )
}
