import React from 'react'

export default function TeamworkContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Teamwork",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"We are always stronger together. Together, everyone accomplishes more. We are always on the lookout for “ideal team players” who are consistently humble, adaptable, hungry for knowledge and relationally intelligent. We strive to provide a FUN work environment with highly collaborative teams working towards a common goal to achieve exceptional results.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Travail en équipe",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nous sommes toujours plus forts ensemble. Ensemble, chacun accomplit plus. Nous sommes toujours à la recherche de joueurs d'équipe idéaux qui sont constamment humbles, adaptables, avides de connaissances et intelligents sur le plan relationnel. Nous nous efforçons de fournir un environnement de travail amusant avec des équipes hautement collaboratives travaillant vers un objectif commun pour obtenir des résultats exceptionnels",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
