import React from 'react'

export default function GoalOrientedContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Goal Oriented",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"We are results oriented and have a strong ethos of surpassing objectives. Our clients rely on us to consistently achieve and in many cases even exceed financial projections, regardless of the circumstances.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Orienté vers les objectifs",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nous sommes axés sur les résultats et avons une forte éthique de dépassement des objectifs. Nos clients comptent sur nous pour toujours atteindre et, dans de nombreux cas, même dépasser les projections financières, quelles que soient les circonstances.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
