import React from 'react'

export default function BusinessContent() {
  return (
    {
        'en': {
            "DedicatedPassionate": "Dedicated, Passionate & Experienced",
            "ProjectsCompleted": "Projects Completed",
            "DollarsFundraised": "Dollars Fundraised",
            "HappyDonors": "Happy Donors",
            
        },
        'fr': {
            "DedicatedPassionate": "Dévoués, Passionnés & Expérimentés",
            "ProjectsCompleted": " Projets complétés",
            "DollarsFundraised": "Dollars recueillis en levées de fonds",
            "HappyDonors": "Donateurs heureux",
          },
    }
  )
}
