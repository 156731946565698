import React from 'react'

export default function ProjectsContent1() {
    return (
        {
            "en": {
                
                        "JoelleFerreira":[{
                        "title": "Joelle Ferreira",
                        "smltitle": "Manager, Annual Campaigns & Donor Services",
                        "subtitle": "It was a pleasure working with Generosité during our yearlong telemarketing campaign. We ran this campaign during an extremely busy time for Food Banks Canada, but they made it so easy. They were quick to home in on our needs, provide suggestions and respond to any questions that popped up throughout the campaign. It was a nice feeling knowing that our donors were speaking to friendly, professional, and well-informed representatives so that I could focus on other projects happening during the pandemic. The daily and weekly reporting they provided was also extremely helpful in allowing us to respond back to donors in a timely manner. I would highly recommend Generosité to any organization that is considering a telemarketing campaign.",
                        }],
                        "NanWehbe": [{
                            "title": "Nan Wehbe",
                            "smltitle": "Director, Laurier Club and National Events",
                            "subtitle": "Generosité has been an outstanding partner in our work to connect with new and long-standing supporters in meaningful ways, and to meet or beat our ambitious fundraising goals."
                        }],
                        "BronwenDearlove": [{
                            "title": "Bronwen Dearlove",
                            "smltitle": "Senior Development Officer, Annual Fund and Database",
                            "subtitle": "Generosité is a strong and reliable partner to the National Arts Centre Foundation. With his years of experience, Thomas Krayer is an excellent resource in phone campaign planning and execution. His telefundraising teams represent the NAC Foundation in a knowledgeable and congenial way, and they always meet their fundraising goals."
                        }],
                        'MarissaCroteau': [{
                            "title": "Marissa Croteau",
                            "smltitle": "Head, Individual Giving",
                            "subtitle": "The Generosité Team has simply been fantastic to work with. Without the support of their professionals, we could never have achieved the results they have doing the work ourselves. The fully bilingual team is responsive and results oriented and we couldn’t ask for a better fundraising partner."
                        }],
                        'SylvieMadely': [{
                            "title": "Sylvie Madely",
                            "smltitle": "Vice-President, Development & Membership",
                            "subtitle": "Working with Generosité Teleservices has always been an extremely positive experience. I have actually had the pleasure of engaging the services of Thomas Krayer for the past 20 years - when with the National Gallery of Canada, then the YMCA-YWCA of the National Capital Region and most recently at the Canadian Museum of History and War. Thomas is focused on results…he asks good questions and takes the time to evaluate different perspectives before making recommendations that will best serve the organization he represents. He takes the time to build relationships with clients which contributes to an open and honest dialogue even when it comes to sensitive topics. He brings a wealth of knowledge to an organization and also possesses rich, humanistic values. His expertise combined with his inner values of contributing to the good of others positions him highly for any philanthropic organization. Thomas equals talent, passion and a desire to succeed! He always delivers on the goals. My most recent experiences working with his company have led me to conclude that the firm is a true reflection of the individual that I have come to know and trust over the years. It is obvious that Thomas’ leadership has trickled down through the ranks of his first-rate organization – from the callers to the most senior level of management. You want to work with Generosité and Thomas. A stronger telemarketing team you'd be hard pressed to find."
                        }],
                        'RachaelWilson': [{
                            "title": "Rachael Wilson",
                            "smltitle": "Chief Executive Officer",
                            "subtitle": "Working with Generosité has been a game changing partnership for the Ottawa Food Bank. The callers at Generosité represent our charity and brand incredibly well. Every campaign we have done with them has been extremely cost effective and has resulted in more donations for our cause and deeper relationships with our donors. I have every confidence in their team."
                        }],
                    
                 },
            "fr": {
                
                
                        "JoelleFerreira": [{
                            "title": "Joelle Ferreira",
                            "smltitle": "Gestionnaire, campagnes annuelles et services aux donateurs",
                            "subtitle": "Ce fut un plaisir de travailler avec Generosité lors de notre campagne de télémarketing d'un an. Nous avons mené cette campagne pendant une période extrêmement occupée pour Banques alimentaires Canada, mais ils l'ont rendue si facile. Ils ont rapidement répondu à nos besoins, fourni des suggestions et répondu à toutes les questions qui surgissaient tout au long de la campagne. C'était un sentiment agréable de savoir que nos donateurs parlaient à des représentants amicaux, professionnels et bien informés afin que je puisse me concentrer sur d'autres projets en cours pendant la pandémie. Les rapports quotidiens et hebdomadaires qu'ils ont fournis ont également été extrêmement utiles pour nous permettre de répondre aux donateurs en temps opportun. Je recommanderais fortement Generosité à toute organisation qui envisage une campagne de télémarketing."
                        }],      
                    
                    
                        "NanWehbe": [{
                            "title": "Nan Wehbe",
                            "smltitle": "Directrice, Club Laurier et événements nationaux",
                            "subtitle": "Generosité a été un partenaire exceptionnel dans notre travail pour nouer des liens significatifs avec des donateurs nouveaux et de longue date, et pour atteindre ou dépasser nos objectifs ambitieux de collecte de fonds."
                        }],

                    
                    
                        "BronwenDearlove": [{
                            "title": "Bronwen Dearlove",
                            "smltitle": "Agente principal de développement, Fonds annuel et base de données",
                            "subtitle": "Generosité est un partenaire solide et fiable de la Fondation du Centre national des Arts. Avec ses années d'expérience, Thomas Krayer est une excellente ressource dans la planification et l'exécution de campagnes téléphoniques. Ses équipes de télé-collecte de fonds représentent la Fondation du CNA d'une manière bien informée et sympathique et elles atteignent toujours leurs objectifs de collecte de fonds."
                        }],
                    
                    
                        'MarissaCroteau': [{
                            "title": "Marissa Croteau",
                            "smltitle": "Chef, Dons individuels",
                            "subtitle": "L'équipe Generosité a été tout simplement fantastique dans l’expérience de travail que nous avons eue avec eux. Sans le soutien de leurs professionnels, nous n'aurions jamais pu atteindre les résultats qu'ils ont obtenus en faisant le travail nous-mêmes. L'équipe entièrement bilingue est réactive et axée sur les résultats et nous ne pouvions pas demander un meilleur partenaire de collecte de fonds"
                        }],  
                    
                
                        'SylvieMadely': [{
                            "title": "Sylvie Madely",
                            "smltitle": "Vice-présidente, Développement et Adhésion",
                            "subtitle": "Travailler avec Generosité Téléservices a toujours été une expérience extrêmement positive. En fait, j'ai eu le plaisir de retenir les services de Thomas Krayer au cours des 20 dernières années - lorsqu'il était au Musée des beaux-arts du Canada, puis au YMCA-YWCA de la région de la capitale nationale et plus récemment au Musée canadien de l'histoire et de la guerre. Thomas est axé sur les résultats... il pose de bonnes questions et prend le temps d'évaluer différentes perspectives avant de faire des recommandations qui serviront au mieux l'organisation qu'il représente. Il prend le temps d'établir des relations avec les clients, ce qui contribue à un dialogue ouvert et honnête, même lorsqu'il s'agit de sujets sensibles. Il apporte une richesse de connaissances à une organisation et possède également de riches valeurs humanistes. Son expertise combinée à ses valeurs profondes de contribuer au bien des autres le positionne pour aider toute organisation philanthropique. Thomas est synonyme de talent, de passion et d'envie de réussir ! Il respecte toujours les objectifs. Mes expériences les plus récentes de travail avec son entreprise m'ont amené à conclure que l'entreprise est le reflet fidèle de l'individu que j'ai appris à connaître et à qui j'ai fait confiance au fil des ans. Il est évident que le leadership de Thomas s'est répercuté dans les rangs de son organisation de premier ordre – de ceux et celles qui font les appels au niveau le plus élevé de la direction. Vous souhaitez travailler avec Generosité et Thomas. Une équipe de télémarketing plus forte que vous auriez du mal à trouver."
                        }],
                    
                    
                        'RachaelWilson': [{
                            "title": "Rachael Wilson",
                            "smltitle": "Cheffe de la direction",
                            "subtitle": "Travailler avec Generosité a été un partenariat qui a changé la donne pour la Banque d’Alimentation d'Ottawa. Ceux et celles qui font les appels chez Generosité représentent incroyablement bien notre association caritative et notre marque. Chaque campagne que nous avons faite avec eux a été extrêmement rentable et a entraîné plus de dons pour notre cause et des relations plus profondes avec nos donateurs. J'ai toute confiance en leur équipe." },  
                    ]}
                 
        });
}
