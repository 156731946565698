import React from "react";
import logo from './logo.svg';
// import './App.css';
import './css/style.css';
import './css/responsive.css';
import Favicon from 'react-favicon';
import {Button} from 'react-bootstrap';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Header from './Header'
import Banner from './Banner'
import About from './About';
import Purpose from './Purpose';
import Get from './Get';
import Sliderpage from './Sliderpage';
import Client from './Client';
import Projects from './Projects'
import Business from './Business'
import Services from './Services'
import Location from './Location'
import Contact from './Contact'
import Footer from './Footer'
import ScrollButton from './ScrollButton'

function App() {
  return (
    <>
    <div className="App">
    <Favicon url={process.env.PUBLIC_URL + '/images/favicon.png'} />
    <Header />
    <Banner />
    <About />
    <Purpose />
    <Get />
    <Sliderpage />
    <Client />
    <Projects />
    <Business />
    <Services />
    <Location />
    <Contact />
    <Footer />
    <ScrollButton />
    </div>
    </>
  );
}

export default App;
