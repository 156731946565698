import React from 'react'

export default function ServiceContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Service",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"An attitude of the heart that translates into wanting to help and give generously. Every interaction is an opportunity to serve our higher nature, expanding the love and goodness in the world. We take customer service and patron satisfaction extremely seriously. Being polite, respectful, truthful and courteous is our golden rule. We reach out to our clients’ patrons and interact with them in a way that reflects positively on their organization.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Service",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Une attitude du cœur qui se traduit par vouloir aider et donner généreusement. Chaque interaction est une opportunité de servir notre nature supérieure, en élargissant l'amour et la bonté dans le monde. Nous prenons très au sérieux le service à la clientèle et la satisfaction des clients. Être poli, respectueux, honnête et courtois est notre règle d'or. Nous tendons la main aux clients de nos organismes-partenaires et interagissons avec eux d'une manière qui reflète positivement leur organisation.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
