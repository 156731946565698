import { Navbar, Nav, NavDropdown, Container, Row, Col, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React, { useEffect, useState, } from "react";
import Slider from "react-slick";
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import ProjectsContent1 from './lang/ProjectsContent';

//import logo from './images/logo.png';

function Projects() {
  const { lang, setLang } = useContext(LangContext);

  const ProjectsContent = ProjectsContent1()[lang];



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var sliderItem = ProjectsContent.JoelleFerreira;
  var sliderItem2 = ProjectsContent.NanWehbe;
  var sliderItem3 = ProjectsContent.BronwenDearlove;
  var sliderItem4 = ProjectsContent.MarissaCroteau;
  var sliderItem5 = ProjectsContent.SylvieMadely;
  var sliderItem6 = ProjectsContent.RachaelWilson;
 
  return (
    <div className="project-wrap pad">
      <Container>
        <Row className='extraRow'>
          {/* <div className="heading-section"><h2>OUR PROJECTS</h2></div> */}
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow}><img alt='' src={process.env.PUBLIC_URL + '/images/fbc.png'} /></Button>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Slider {...settings}>
                      {
                        sliderItem.map((item, index) => {
                          return (

                            <div className="project-slide-box text-center mb-5">
                              <div className="slider-content">
                                <div className="icon-out"><img alt='' src={process.env.PUBLIC_URL + '/images/fbc.png'} className="img-fluid m-auto" /></div>
                                <p>{item.subtitle}</p>
                                <h3>{item.title}</h3>
                                <h4>{item.smltitle}</h4>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

          </Col>
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow2}>
                  <img alt='' src={process.env.PUBLIC_URL + '/images/liberal.png'} className="img-fluid" />
                </Button>
              </div>

              <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Slider {...settings}>
                    {
                      sliderItem2.map((item, index) => {
                        return (

                          <div className="project-slide-box text-center mb-5">
                            <div className="slider-content">
                              <div className="icon-out">
                                <img alt='' src={process.env.PUBLIC_URL + '/images/liberal.png'} className="img-fluid m-auto" />
                              </div>
                              <p>{item.subtitle}</p>
                              <h3>{item.title}</h3>
                              <h4>{item.smltitle}</h4>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow3} ><img alt='' src={process.env.PUBLIC_URL + '/images/nac.png'} className="img-fluid " /></Button>
                <Modal
                  show={show3}
                  onHide={handleClose3}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Slider {...settings}>
                      {
                        sliderItem3.map((item, index) => {
                          return (

                            <div className="project-slide-box text-center mb-5">
                              <div className="slider-content">
                                <div className="icon-out">
                                  <img alt='' src={process.env.PUBLIC_URL + '/images/nac.png'} className="img-fluid m-auto" />
                                </div>
                                <p>{item.subtitle}</p>
                                <h3>{item.title}</h3>
                                <h4>{item.smltitle}</h4>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow4}>
                  <img alt='' src={process.env.PUBLIC_URL + '/images/cmn.png'} className="img-fluid " />
                </Button>
                <Modal
                  show={show4}
                  onHide={handleClose4}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Slider {...settings}>
                      {
                        sliderItem4.map((item, index) => {
                          return (

                            <div className="project-slide-box text-center mb-5">
                              <div className="slider-content">
                                <div className="icon-out">
                                  <img alt='' src={process.env.PUBLIC_URL + '/images/cmn.png'} className="img-fluid m-auto" />
                                </div>
                                <p>{item.subtitle}</p>
                                <h3>{item.title}</h3>
                                <h4>{item.smltitle}</h4>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow5}>
                  <img alt='' src={process.env.PUBLIC_URL + '/images/CM.png'} className="img-fluid " />
                </Button>
                <Modal
                  show={show5}
                  onHide={handleClose5}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Slider {...settings}>
                      {
                        sliderItem5.map((item, index) => {
                          return (

                            <div className="project-slide-box text-center mb-5">
                              <div className="slider-content">
                                <div className="icon-out">
                                  <img alt='' src={process.env.PUBLIC_URL + '/images/CM.png'} className="img-fluid m-auto" />
                                </div>
                                <p>{item.subtitle}</p>
                                <h3>{item.title}</h3>
                                <h4>{item.smltitle}</h4>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="project-box">
              <div className="img-box">
                <Button variant="primary" onClick={handleShow6}>
                  <img alt='' src={process.env.PUBLIC_URL + '/images/OFB.png'} className="img-fluid " />
                </Button>
                <Modal
                  show={show6}
                  onHide={handleClose6}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Slider {...settings}>
                      {
                        sliderItem6.map((item, index) => {
                          return (

                            <div className="project-slide-box text-center mb-5">
                              <div className="slider-content">
                                <div className="icon-out">
                                  <img alt='' src={process.env.PUBLIC_URL + '/images/OFB.png'} className="img-fluid m-auto" />
                                </div>
                                <p>{item.subtitle}</p>
                                <h3>{item.title}</h3>
                                <h4>{item.smltitle}</h4>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Slider>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Projects