import React from 'react'

export default function AutonomyContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Autonomy",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Our colleagues are mature and articulate individuals capable of engaging our clients’ patrons in stimulating and mutually rewarding conversations. To enable them to be as self-reliant as possible, we provide them with a solid foundation of knowledge pertaining to the campaigns they are working on and the organization they are representing. Then, we give them the leeway they need to be effective self-starters while continuing to provide them with all the resources they need to excel at their jobs. We do not believe in coercion and micro-management. We believe in treating our colleagues as responsible adults who will do what is right.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Autonomie",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nos collègues sont des personnes mûres et articulées capables d'engager les clients des organismes que nous représentons dans des conversations stimulantes et mutuellement enrichissantes. Pour leur permettre d'être aussi autonomes que possible, nous leur fournissons une base solide de connaissances relatives à la campagne sur laquelle ils travaillent et à l'organisation qu'ils représentent. Ensuite, nous leur donnons la marge de manœuvre nécessaire pour être autonomes et efficaces tout en continuant à leur fournir toutes les ressources dont ils ont besoin pour exceller dans leur travail. Nous ne croyons pas à la coercition et à la micro-gestion. Nous croyons qu'il faut traiter nos collègues comme des adultes responsables qui feront ce qui est juste.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
