import React from "react";
import {Navbar,Nav,NavDropdown,Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//import logo from './images/logo.png';

function Banner()
{
    return (
        <div className="Banner">
         <section className="banner-wrap">
         <video
            className="slider-video"
            src={require('./videos/Background.mp4')}
            loading="lazy"
            loop
            autoPlay
            muted 
            playsinline=""
            preload="auto"
          ></video>
          <Container>
           <Row>
            <div className="banner-box">
            <img src={process.env.PUBLIC_URL + '/images/WebAniWhite.gif'} className="img-fluid" />;
            </div>
           </Row>
          </Container>   
         </section>
        </div>
    )
}

export default Banner