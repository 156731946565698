import React from 'react'

export default function DedicationContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Dedication",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"We never give up – always willing to do whatever it takes to ensure success on our campaigns. Tenacity, attention to detail and willingness to “go the extra mile” are all part of our collective DNA. We purposely choose to limit the number of projects we are actively working on at any given time, such that we can be laser-focused on the success of each individual campaign.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Dévouement",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nous n'abandonnons jamais - toujours prêts à faire tout ce qu'il faut pour assurer le succès de nos campagnes. La ténacité, le souci du détail et la volonté de « faire un effort supplémentaire » font tous partie de notre ADN collectif. Nous choisissons délibérément de limiter le nombre de projets sur lesquels nous travaillons activement à un moment donné, de sorte que nous puissions nous concentrer sur le succès de chaque campagne individuelle.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
