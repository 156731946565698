

export default function CoreValuesContent() {
    return (
        {
            'en': {
              "home": "Home",
              "about": "Core Values",
              "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
              "aboutUsText":"We try to make the world a better place by empowering not-for-profit organizations, helping them to thrive and succeed in a unique way. We provide a community of colleagues with meaningful employment and a caring professional environment while empowering and supporting them to flourish and grow.",
              "pageDoesNotExist": "Page does not exist",
              "returnToHome": "Return to home"
            },
            'fr': {
              "home": "Accueil",
              "about": "Valeurs fondamentales",
              "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
              "aboutUsText":"Nous essayons de rendre le Monde meilleur en favorisant l’autonomie des organismes à but non lucratif, en les aidant, à notre manière unique à se développer et s’épanouir; tout en offrant à une communauté de collègues un emploi significatif, un environnement professionnel bienveillant ainsi que le soutien et les moyens de promouvoir l’épanouissement de chaque individu au sein de l'entreprise.",
              "pageDoesNotExist": "Page does not exist",
              "returnToHome": "Return to home"
            },
          }
    
  )
}
