import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
//import logo from './images/logo.png';
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import AboutContent from './lang/AboutContent';

function About() {
    const { lang, setLang } = useContext(LangContext);

    const PageContent = AboutContent()[lang];
    return (
        <div className="About" id="About">
            <section className="about-wrap pad">
                <Container>
                    
                    <Row className="align-items-center">
                        <Col md={{ span: 5, order: 1 }} sm={{ order: 2 }}>
                            <div className="about-img-box">
                                <img src={process.env.PUBLIC_URL + '/images/about-us.png'} className="img-fluid" />;
                            </div>
                        </Col>
                        <Col md={{ span: 6, offset: 1, order: 2 }} sm={{ order: 1 }}>
                            <div className="about-text">
                                <div className="heading-section"><h2>{PageContent.about}</h2></div>
                                {/* <p><b>Generosité Fundraising & Communications goal is simple</b></p> */}
                                <p dangerouslySetInnerHTML={{ __html: PageContent.aboutUsText }}></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default About