
import React, { useState, createContext } from "react";

export const LangContext = createContext();

export default function LangProvider(props) {

    const [lang, setLang] = useState('fr');
    // const getUrl = window.location.href;
    // let replaseUrl = getUrl.replace('https://generositefundraising.com/', '');
    // let langUrl = replaseUrl.split('/')[0];
    // if (langUrl === 'en' || langUrl === 'fr') {
    //     setLang(`${langUrl}`);
    // }

    return (
        <LangContext.Provider
            value={{
                lang,
                setLang
            }}
        >
            {props.children}
        </LangContext.Provider>
    )
}
