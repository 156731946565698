import {Navbar,Nav,NavDropdown,Container, Row, Col} from 'react-bootstrap'
//import logo from './images/logo.png';
import { Link } from "react-scroll";
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import GetContent from './lang/GetContent';

function Get()
{
  const { lang, setLang } = useContext(LangContext);

  const Get = GetContent()[lang];
    return (
        <div className="Get" id="Get">
         <section className="purpose-wrap pad">
          <Container>
          <Row>
          <Col md={{ span: 12}}>
                <p className="get-in-touch-wrapper text-center"> {Get.beforeLinkText} <Link href="" activeClass="active" to="contact-wrap" spy={true} smooth={true} offset={-100} duration={300}>{Get.linkText }</Link>.</p>
            </Col>
            </Row>

          </Container>
         </section>
        </div>
    )
}

export default Get