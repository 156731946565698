import {Container, Row, Button} from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter,faShopify,syringe} from "@fortawesome/free-brands-svg-icons";

function Location() {
    
    return (
        <>
       <div className="location-wrap pad" id="location-wrap">
       <iframe className="map-center" width="" height="450"  loading="lazy" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJuUiWhroFzkwR0-ZlxDIqCDw&amp;key=AIzaSyBBiHaVadBg8mJvKR1_VNHY-VQWp_wqY0Y"></iframe>
      </div>
   </>
    
    )
}

export default Location