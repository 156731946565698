import {Container, Row, Button} from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faShopify, syringe } from "@fortawesome/free-brands-svg-icons";
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import OurServicesContent from './lang/OurServicesContent';

function Services()
{
  const { lang, setLang } = useContext(LangContext);
  const PageContent = OurServicesContent()[lang];
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    var sliderItem = [{title:PageContent.TelephoneFundraising.title,subtitle:PageContent.TelephoneFundraising.TelephoneFundraisingText,desc:"desc1",images: "/images/phone.png"},{title:PageContent.SMSFundraising.title,subtitle:PageContent.SMSFundraising.SMSFundraisingText,desc:"desc2",images: "/images/chat-bubble.png"},
    {title:PageContent.EmailFundraising.title,subtitle:PageContent.EmailFundraising.EmailFundraisingText,desc:"desc3",images: "/images/mail.png"},
    {title:PageContent.DirectMailFundraising.title,subtitle:PageContent.DirectMailFundraising.DirectMailFundraisingText,desc:"desc4",images: "/images/direct-mail.png"}];
    console.log(sliderItem)
    return (
        <>
       <div className="service-wrap pad">
        <Container>
         <Row>
              <div className="heading-section"><h2>{PageContent.title}</h2></div>
          <Slider {...settings}>
        {
          sliderItem.map((item,index) => {
                return (
                
                  <div className="service-box shadow mb-5">
                    <div className="slider-content">
                    <div className="icon-out">
                    <img src={item.images} className="img-fluid" />
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                    </div>
                  </div>
                )
              })
        }
        </Slider>
        </Row>
      </Container>   
    </div>
   </>
    
    )
}

export default Services