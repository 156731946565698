import React from 'react'

export default function OurServicesContent() {
  return (
      {
        
        'en': {
            "title": "Our Services",
            "TelephoneFundraising": {
                "title": "Telephone Fundraising",
                "TelephoneFundraisingText": "One of the major reasons why donors don’t contribute to a particular cause is often because they simply were never asked. At Generosité Fundraising & Communications, we believe it’s not just a matter of asking for a donation, but also a matter of asking the right way. By building rapport with donors and engaging them in meaningful dialogue, Generosité engages with donors in a way that builds long-lasting relationships with the organization. As such, raising money is not the only goal of our telephone solicitation campaigns. Conversations that end without a donation can still prove to be useful. Basing success on donations alone overlooks relationship-building with donors that may help cultivate recurring gifts, major gifts, and legacy giving opportunities later in the donor’s lifetime with the organization. Establishing a campaign’s success on long-term connections helps organizations raise their public profile and brand awareness in a positive way. Integrated in a holistic, multi-channel approach, telephone fundraising calls can aid the organization in keeping their donors engaged, providing the latest information for the cause they believe in. Well-informed donors provide more frequent support."
              },
              "SMSFundraising": {
                  "title": "SMS Fundraising",
                    "SMSFundraisingText": "As time has gone on, the traditional means of reaching your constituents are becoming less and less effective. Working hand-in-hand with telephone fundraising and other direct fundraising streams, SMS messaging is an effective way to maximize the chances of soliciting a donation and continuing donor engagement. Generosité Fundraising & Communications can send and receive text messages in just minutes with our online SMS platform. Should you choose to use this service, we can send bulk text messages to every constituent in your database with customizable instant automated responses. Messages can be opted-out and each message will be tracked for delivery statistics, bounces, opt-outs, and responses. Our software can add an additional tracking element by including a shortened URL to measure click-through and conversion rates."
              },
              "EmailFundraising": {
                  "title": "Email Fundraising",
                  "EmailFundraisingText":"Seamlessly integrated email campaigns are a key part of any organization’s digital fundraising strategy. Generosité Fundraising & Communications applies its personalized approach with highly targeted emails created collaboratively with your organization to maximize constituent engagement resulting in long-lasting donor relationships. Supplementing any existing fundraising programme- Generosité can append, mail-merge, and send personalized links embedded in an email to donors on an organization’s database. In-depth statistics will be tracked, measured, and reported on so you can monitor important campaign metrics. We offer a flexible, scalable solution to meet your needs."
              },
              "DirectMailFundraising": {
                  "title": "Direct Mail Fundraising",
                  "DirectMailFundraisingText": "While there has been a rise in digital platforms for fundraising, direct mail solicitations are often still the bread-and-butter of any organization’s fundraising strategy. Generosité Fundraising & Communications will work with you to develop print-marketing materials for soliciting funds, stewardship, and whatever you believe is needed to spread your message. Generosité can send approved materials to your constituents coast-to-coast within Canada. Generosité provides direct mail services fully integrated to your current campaigns with a full suite of customized reports to match your needs"
              },
           
        },
        'fr': {
            "title": "Nos services",
            "TelephoneFundraising": {
                "title": " Levée de fonds par téléphone",
                "TelephoneFundraisingText":"L'une des principales raisons pour lesquelles les donateurs ne contribuent pas à une cause particulière est souvent parce qu'on ne leur a tout simplement jamais demandé. Chez Generosité Levées de fonds & Communications, nous croyons qu'il ne s'agit pas seulement de demander un don, mais aussi de demander de la bonne manière. En établissant des liens avec les donateurs et en les engageant dans un dialogue significatif, Generosité s'engage auprès des donateurs d'une manière qui établit des relations durables avec l'organisation. Établir le succès d'une campagne sur des relations à long terme aide les organisations à accroître leur profil public et la notoriété de leur marque de manière positive. Intégrés dans une approche holistique et multicanal, les appels téléphoniques de collecte de fonds peuvent aider l'organisation à maintenir l'engagement de ses donateurs, en fournissant les dernières informations sur la cause en laquelle ils croient. Des donateurs bien informés apportent un soutien plus fréquent."
            },
            "SMSFundraising": {
                "title": "Levée de fonds par SMS",
                  "SMSFundraisingText": "Au fil du temps, les moyens traditionnels d'atteindre votre clientèle deviennent de moins en moins efficaces. Travaillant main dans la main avec la collecte de fonds par téléphone et d'autres méthodes de collecte de fonds directs, la messagerie SMS est un moyen efficace de maximiser les chances de solliciter un don et de poursuivre l'engagement des donateurs. Generosité Levées de fonds & Communications peut envoyer et recevoir des SMS en quelques minutes grâce à notre plateforme SMS en ligne. Si vous choisissez d'utiliser ce service, nous pouvons envoyer des messages texte en masse à chaque personne de votre base de données avec des réponses automatisées instantanées personnalisables. Les messages peuvent être supprimés et chaque message sera suivi pour la livraison, les statistiques, les rebonds, les refus et les réponses. Notre logiciel peut ajouter un élément de suivi supplémentaire en incluant un raccourci URL pour mesurer les taux de clics et de conversation."
            },
            "EmailFundraising": {
                "title": "Levée de fonds par courriel",
                "EmailFundraisingText":"Les campagnes par courriel parfaitement intégrées sont un élément clé de la stratégie de levée de fonds numérique de toute organisation. Generosité Levées de fonds & Communications applique son approche personnalisée avec des courriels hautement ciblés créés en collaboration avec votre organisation pour maximiser l'engagement de votre clientèle, ce qui se traduit par des relations durables avec les donateurs. Complétant tout programme de levée de fonds existant - Generosité peut ajouter, fusionner et envoyer des liens personnalisés intégrés dans un courriel aux donateurs sur la base de données d'une organisation. Des statistiques détaillées seront suivies, mesurées et rapportées afin que vous puissiez surveiller les mesures importantes de la campagne. Nous offrons une solution flexible et évolutive pour répondre à vos besoins."
            },
            "DirectMailFundraising": {
                "title": "Levée de fonds par publipostage",
                "DirectMailFundraisingText": "Bien qu'il y ait eu une augmentation des plateformes numériques pour la collecte de fonds, les sollicitations par publipostage sont souvent encore le pain quotidien de la stratégie de collecte de fonds de toute organisation. Generosité Levées de fonds & Communications travaillera avec vous pour développer du matériel de marketing imprimé pour solliciter des fonds, l'intendance et tout ce que vous croyez nécessaire pour diffuser votre message. Generosité peut envoyer du matériel approuvé à votre clientèle d'un océan à l'autre au Canada. Generosité fournit des services de publipostage entièrement intégrés à vos campagnes actuelles avec une suite complète de rapports personnalisés pour répondre à vos besoins."
            },
        },
    }
  )
}
