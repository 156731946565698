import {Navbar,Nav,NavDropdown,Container, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//import logo from './images/logo.png';
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import BusinessContent from './lang/BusinessContent';

function Business()
{
  const { lang, setLang } = useContext(LangContext);
  
  const PageContent = BusinessContent()[lang];
    return (
        <div className="business-wrap pad">
         <div className="business-box-out">
          <video
            className="slider-video"
            src={require('./videos/Callcentre.mp4')}
            loading="lazy"
            loop
            autoPlay
            muted 
            playsinline=""
          ></video>
          <Container>
           <Row>
           <Col>
            <div className="business-box">
                  <h3>{PageContent.DedicatedPassionate}</h3>
              <Row className="justify-content-md-center">
               <Col md={3}>
                <div className="text-box">
                        <h5>{PageContent.ProjectsCompleted }</h5>
                 <p>134</p>
                </div>
               </Col>
               <Col md={5}>
                <div className="text-box">
                        <h5>{PageContent.DollarsFundraised }</h5>
                 <p>$ 17,663,065.08</p>
                </div>
               </Col>
               <Col md={3}>
                <div className="text-box">
                        <h5>{PageContent.HappyDonors }</h5>
                 <p>∞</p>
                </div>
               </Col>
              </Row>
            </div>
           </Col>
           </Row>   
          </Container>   
         </div>
        </div>
    )
}

export default Business