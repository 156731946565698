import React from 'react'

export default function getContent() {
  return (
    {
        'en': {
            "linkText": "Get in touch",
            "beforeLinkText": "Is this something that sounds like you would like to be a part of?",
        },
        'fr': {
            "linkText": "Contactez-nous",
            "beforeLinkText": "Est-ce quelque chose qui vous semble intéressant?",
        },
    }
  )
}
