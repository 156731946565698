import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { LangContext } from './LangProvider';

function Client() {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <footer className="Footer-wrap p-4">
      <Container>
        <Row className="align-items-center">
          <Col md={12}>
            <div className="text-center">
              <div className="footer-icon-box">
                <a href="https://www.facebook.com/generositefundraising" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://www.instagram.com/generosite_fundraising/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.linkedin.com/company/generosite/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
              <p>© Copyright {currentYear} - Powered by w3.css</p>
              {/* <div className="custom_box">
                <div className="custom_select">
                  <select onChange={handleYearChange}>
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Client;
