import React from "react";
import {Navbar,Nav,NavDropdown,Container, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//import logo from './images/logo.png';
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import NavBarContent from './lang/NavBarContent';

function Client()
{
    const { lang, setLang } = useContext(LangContext);
    
    const PageContent = NavBarContent()[lang];
    return (
        <div className="client-wrap">
            <div className="our-client-box">
            <video
            className="slider-video"
            src={require('./videos/donation.mp4')}
            loading="lazy"
            loop
            autoPlay
            muted 
            playsinline=""
          ></video>
              <div className="text-client">
                    <h4>{PageContent.ourClients}</h4>
                    <p>{PageContent.ourClientsSubTitle }</p>
             </div>
            </div>
        </div>
    )
}

export default Client