import {Navbar,Nav,NavDropdown,Container, Row, Placeholder, Card, Button,} from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import MeetOurTeamContent from './lang/MeetOurTeamContent';
//import logo from './images/logo.png';

function Sliderpage()
{
  const { lang, setLang } = useContext(LangContext);
  const PageContent = MeetOurTeamContent()[lang];
  console.log('PageContent',PageContent);
    var settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  var sliderItem = [{
    title: "Thomas Krayer",
    subtitle: PageContent.ThomasKrayer.title,
    desc: "desc1",
    facebook: "#",
    linkedin: "https://ca.linkedin.com/in/thomas-krayer-56521814",
    twitter: "#",
    content: PageContent.ThomasKrayer.ThomasKrayerText,
    images: "/images/ThomasProfile.jpg"
  }, {
    title: "Seth Wilcox",
    subtitle: PageContent.SethWilcox.title,
    desc: "desc2",
    facebook: "https://www.facebook.com/wilcox.seth",
    linkedin: "https://ca.linkedin.com/in/wilcox-seth",
    twitter: "#",
    content: PageContent.SethWilcox.SethWilcoxText,
    images: "/images/SethProfilePicture.jpg"
    }, {
    title: "Luc Perron",
    subtitle: PageContent.LucPerron.title,
    desc: "desc3", facebook: "#",
    linkedin: "https://ca.linkedin.com/in/luc-perron-33871985",
    twitter: "#",
    content: PageContent.LucPerron.LucPerronText,
    images: "/images/LucProfile.jpg"
    },
    {
      title: "Jean-François Séguin",
      subtitle: PageContent.JeanFrançois.title,
      desc: "desc4",
      facebook: "#",
      linkedin: "#",
      twitter: "#",
      content: PageContent.JeanFrançois.JeanFrancoisText,
      images: "/images/JFProfile.jpg"
    },
    {
      title: 'Rosanna McLaughlin',
      subtitle: PageContent.RosannaMcLaughlin.title,
      desc: "desc5",
      facebook: "#",
      linkedin: "#",
      twitter: "#",
      content: PageContent.RosannaMcLaughlin.RosannaMcLaughlinText,
      images: "/images/RosannaProfile.jpg"
    },
    {
      title: 'Colombe Gagné-Henley',
      subtitle: PageContent.GagnéHenley.title,
      desc: "desc6", facebook: "#",
      linkedin: "#", twitter: "#",
      content: PageContent.GagnéHenley.GagneHenleyText,
      images: "/images/ColombeProfile.jpg"
    }];
    console.log(sliderItem)
    
    return (
        <>
       <div className="Slider-wrap pad">
        <Container>
         <Row>
              <div className="heading-section"><h2>{PageContent.title}</h2></div>
          <Slider {...settings}>
        {
          sliderItem.map((item,index) => {
                return (
                  <div className="slider-box shadow mb-5">
                    <div className="box-bg"></div>
                    <div className="slider-content">
                    <img src={item.images} className="img-fluid" />
                    <h3>{item.title}</h3>
                    <h4>{item.subtitle}</h4>
                    <div className="box-icons">
                      {
                        (item.facebook && item.facebook != '#')?
                        <a href={item.facebook} target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                        :''
                       }
                       {
                        (item.linkedin && item.linkedin != '#')?
                        <a href={item.linkedin} target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                        :''
                      }
                      {
                        (item.twitter && item.twitter != '#')?
                       <a href={item.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                       :''
                      }
                    </div>
                    </div>
                    <div className="box-layer">
                     <div className="box-layer-cont">
                      <h3>{item.title}</h3>
                      <h4>{item.subtitle}</h4>
                      <p>
                      {item.content}
                      </p>
                      <div className="box-icons">
                      {
                        (item.facebook && item.facebook != '#')?
                        <a href={item.facebook} target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                        :''
                      }
                      {
                        (item.linkedin && item.linkedin != '#')?
                        <a href={item.linkedin} target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                        :''
                      }
                      {
                        (item.twitter && item.twitter != '#')?
                       <a href={item.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                       :''
                      }
                    </div>
                    </div>
                    </div>
                  </div>
                )
              })
        }
        </Slider>
        </Row>
      </Container>   
    </div>
   </>
    
    )
}

export default Sliderpage