import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
// import {Link} from 'react-router-dom'
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faCoffee, faBars, faXmark, faLanguage  } from '@fortawesome/free-solid-svg-icons'
import { LangContext } from './LangProvider';
import { useContext } from 'react'
import NavBarContent from './lang/NavBarContent';


function Header() {
    const { lang, setLang } = useContext(LangContext);

    const Header = NavBarContent()[lang];

    const changeLang = (e) => {
        (e.target.innerHTML != "English" && e.target.innerHTML != undefined && e.target.innerHTML != "") ? setLang("fr") : setLang("en");
    }
    
useEffect(() => {
    if(window.location.pathname == "/fr"){
      setLang("fr");
    } else {
      setLang("en");
    }
  }, []);
    
    useEffect(() => {
      if (lang) {
        window.history.pushState({}, "", lang);
  
      }
      }, [lang]);
  
    useEffect(() => {

        document.documentElement.lang = lang;
             }, [lang])

    return (
        <div className="Header">
            
            <Navbar collapseOnSelect expand="lg" bg="" variant="dark">
                <Container>
                    
                    <Navbar.Brand href="#home"><img src={process.env.PUBLIC_URL + '/images/logo.gif'} />;</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <FontAwesomeIcon icon={faBars}>
                        </FontAwesomeIcon>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Navbar.Toggle className="close-icon" aria-controls="responsive-navbar-nav">
                            <FontAwesomeIcon icon={faXmark}>
                            </FontAwesomeIcon>
                        </Navbar.Toggle>
                        <Nav className="m-auto navs-cont">
                            <Link href="" activeClass="active" to="Banner" spy={true} smooth={true} offset={-100} duration={300}>{Header.home}</Link>
                            <Link href="" activeClass="active" to="About" spy={true} smooth={true} offset={-100} duration={300}>
                                {Header.about}
                            </Link>
                            <Link href="" activeClass="active" to="Purpose" spy={true} smooth={true} offset={-100} duration={300}>
                                {Header.CoreValues}
                            </Link>
                            <Link href="" activeClass="active" to="client-wrap" spy={true} smooth={true} offset={-100} duration={300}>
                                {Header.ourClients}
                            </Link>
                            <Link href="" activeClass="active" to="service-wrap" spy={true} smooth={true} offset={-100} duration={300}>
                                {Header.ourServices}
                            </Link>
                            <Link href="" activeClass="active" to="contact-wrap" spy={true} smooth={true} offset={-100} duration={300}>
                                {Header.contactUs}
                            </Link>
                            <Link className="lang_tag"><span className="lang"  onClick={changeLang}>{(lang != "fr") ? "Français" : "English"}</span></Link>
                        </Nav>
                        <Nav className="top-navicon">
                            
                            <Nav.Link href="https://www.facebook.com/generositefundraising" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></Nav.Link>
                            <Nav.Link eventKey={2} href="https://www.instagram.com/generosite_fundraising/" target="_blank"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></Nav.Link>
                            <Nav.Link eventKey={2} href="https://www.linkedin.com/company/generosite/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon></Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                   
                </Container>

            </Navbar>
        </div>
    )
}

export default Header