import React from 'react'

export default function AuthenticityContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Authenticity",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"We truly believe in the mission and causes of the organizations we partner with. It’s a point of departure for any interaction we have on behalf of our clients: a heartfelt desire to advocate for the intrinsic virtues of that mission or cause.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Authenticité",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nous croyons vraiment à la mission et aux causes des organisations avec lesquelles nous collaborons. C'est un point de départ pour toute interaction que nous avons au nom de nos clients : un désir sincère de défendre les vertus intrinsèques de cette mission ou de cette cause.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
