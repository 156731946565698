import React from 'react'

export default function HonestyContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Honesty & Integrity",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"We never get involved with a cause that we can’t get behind. We truly believe that every organization that we partner with has a mission that makes them a positive force for good on the planet. We speak with genuine conviction on behalf of our clients, without ever jeopardizing the integrity and consistency of their core message.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Honnêteté et intégrité",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nous ne nous impliquons jamais dans une cause que nous ne pouvons pas défendre en toute sincérité. Nous sommes convaincus que chaque organisation avec laquelle nous nous associons a une mission qui en fait une force positive pour le bien sur la planète. Nous parlons avec une véritable conviction au nom de nos clients, sans jamais compromettre l'intégrité et la cohérence de leur message principal.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
