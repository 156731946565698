import React from 'react'

export default function TrustContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Trust",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Our colleagues are reasonable people who can be trusted to do the right thing. They are creative, thoughtful, reliable, self-motivated, intelligent, trustworthy adults; capable of making important decisions. They are accountable and responsible for their actions. They are unique and they want to use their talents and skills to make a positive contribution to the organization and the world. All colleagues are essentially considered to be good unless proven otherwise.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "Confiance",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Nos collègues sont des personnes raisonnables à qui on peut faire confiance pour faire ce qu'il faut. Ce sont des adultes créatifs, réfléchis, fiables, motivés, intelligents et dignes de confiance ; capables de prendre des décisions importantes. Ils sont redevables et responsables de leurs actes. Ils sont uniques et veulent utiliser leurs talents et leurs compétences pour apporter une contribution positive à l'organisation et au monde. Tous les collègues sont essentiellement considérés comme bons, sauf preuve du contraire.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
