import React from 'react'

export default function NavBarContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "About Us",
            "CoreValues": "Core Values",
            "ourClients": "Our Work",
            "ourServices": "Our Services",
        "contactUs": "Contact Us",
            "ourClientsSubTitle": "Clients we have partnered with",

        },
        'fr': {
            "home": "Accueil",
            "about": "À propos de nous",
            "CoreValues": "Valeurs fondamentales",
            "ourClients": "Notre travail",
            "ourServices": "Nos services",
          "contactUs": "Contactez-nous",
            "ourClientsSubTitle": "Des clients avec lesquels nous avons collaboré",
        },
    }
  )
}
