import React from 'react'

export default function EnvironmentalContent() {
  return (
    {
        'en': {
            "home": "Home",
            "about": "Environmental Sustainability",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Minimizing our ecological footprint is important to us. We plant a tree for every successful call we make on behalf of our clients, and we strive to minimize resource consumption in our office and in our business practices in general.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
        'fr': {
            "home": "Accueil",
            "about": "La durabilité environnementale",
            "introText": "We are a grou of people who are passionate about the web and we want to share our knowledge with you.",
            "aboutUsText":"Minimiser notre empreinte écologique est important pour nous. Nous plantons un arbre pour chaque appel réussi que nous faisons au nom de nos organismespartenaires. Aussi, nous nous efforçons de minimiser la consommation de ressources dans notre bureau et dans nos pratiques commerciales en général.",
            "pageDoesNotExist": "Page does not exist",
            "returnToHome": "Return to home"
        },
    }
  )
}
